import Quill from 'quill';

const initQuill = () => {
  /**
   * Custom blot for inline icon
   */
  const Inline = Quill.import('blots/inline');
  const InlineEmbed = Quill.import('blots/embed');
  class IconBlot extends InlineEmbed {
    static blotName = 'icon';
    static tagName = 'icon';
  }
  Quill.register(IconBlot);


  /**
   * Custom blot for variable pills
   */
  class PillBlot extends InlineEmbed {
    static blotName = 'pill-blot';
    static tagName = 'span';
    static className = 'ql-pill';

    static create(data) {
      let node = super.create();
      node.dataset.id = data.variableName;
      node.dataset.data = JSON.stringify(data);
      node.contentEditable = false;
      node.classList.add(this.className);
      node.textContent = null;
      node.draggable = true;
      node.style.border = `1px solid ${data.darkColor}`;
      node.style.whiteSpace = 'nowrap';

      const iconElement = document.createElement('icon');
      iconElement.className = data.icon;
      iconElement.style.color = data.darkColor;
      iconElement.style.marginRight = '5px';
      node.appendChild(iconElement);

      if (data.displayName) node.appendChild(document.createTextNode(`${data.displayName}: `));
      node.appendChild(document.createTextNode(`${data.value}`));

      // Allow draging inside the editor
      node.addEventListener('dragstart', (e) => {
        e.stopPropagation();
        const blot = Quill.find(node);
        const index = blot.offset();
        data.removeRange = { index, length: blot.length() };
        e.dataTransfer.setData('pill-params', JSON.stringify(data));
      });

      node.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        const blot = Quill.find(node);
        const index = blot.offset();
        if (data.callback) data.callback(index);
        node.classList.add('ql-pill-active');
        document.addEventListener('click', (e) => {
          if (node.contains(e.target)) return;
          node.classList.remove('ql-pill-active');
          document.removeEventListener('click', () => {});
        });
      });
      node.addEventListener('mouseover', (e) => {
        node.classList.add('ql-pill-hover')
      });
      node.addEventListener('mouseout', (e) => {
        node.classList.remove('ql-pill-hover')
      });

      return node;
    }

    length() {
      return 1;
    }

    static value(node) {
      return node.dataset.data;
    }

    static formats(node) {
      return node.dataset.data;
    }
  }
  Quill.register(PillBlot);
};


export default initQuill;