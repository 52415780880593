<script>
const StateOptions = Object.freeze({
  Initial: 'initial',
  Working: 'working',
  Done: 'done',
});

export default {
  name: 'HrbrAgreementEditorSaveIndicator',

  props: {},

  data() {
    return {
      currentState: StateOptions.Initial,
      stateMessages: {
        [StateOptions.Initial]: {
          icon: 'fa-cloud-check',
          message: null,
        },
        [StateOptions.Working]: {
          icon: 'fa-rotate',
          message: 'Saving...',
        },
        [StateOptions.Done]: {
          icon: 'fa-rotate',
          message: 'Saved',
        },
      },
      timeoutId: null,
    };
  },

  computed: {
    currentIcon() {
      return this.stateMessages[this.currentState]?.icon || null;
    },
    currentMessage() {
      return this.stateMessages[this.currentState].message || null;
    },
  },

  methods: {
    // method for external use
    updateIndicatorStates() {
      this.currentState = StateOptions.Working;
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.currentState = StateOptions.Done;
        setTimeout(() => {
          this.currentState = StateOptions.Initial;
        }, 1000);
      }, 3000);
    },
  },

  beforeDestroy() {
    clearTimeout(this.timeoutId);
  },
};
</script>

<template>
  <div class="hrbr-agreement-editor-save-indicator">
    <div class="hrbr-agreement-editor-save-indicator__icon">
      <i :class="'fa-light ' + currentIcon"></i>
    </div>
    <div class="hrbr-agreement-editor-save-indicator__message" v-if="currentMessage">
      {{ currentMessage }}
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-agreement-editor-save-indicator {
  font-size: 12px;
  font-weight: 500;
  color: #4a4a4a;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  height: 20px;
  padding: 0 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
  user-select: none;

  &__icon {
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__message {
    font-size: inherit;
    line-height: 1.1;
  }
}
</style>
