import Vue from 'vue';
import VueRouter from 'vue-router';
import { useHarbourStore } from '@/stores/harbour-store';
import handleDynamicError from '@/utils/dynamic-errors';

// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) return err;
    return Promise.reject(err);
  });
};

Vue.use(VueRouter);

const checkAccess = (routePermissions) => {
  const harbourStore = useHarbourStore();
  return harbourStore.checkUserAuthPermissions(routePermissions);
};

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import('../pages/Main/HrbrMainView.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../pages/Dashboard/HrbrDashboardView.vue'),
    meta: {
      hasAccess: () => checkAccess(['permissions.links.view']),
    },
  },
  {
    path: '/signed-by-me',
    name: 'signed-by-me',
    component: () => import('../pages/SignedByMe/HrbrSignedByMeView.vue'),
    meta: {
      hasAccess: () => checkAccess(['permissions.links.view']),
    },
  },
  {
    path: '/awaiting-my-review',
    name: 'awaiting-my-review',
    component: () => import('../pages/AwaitingReview/HrbrAwaitingReviewView.vue'),
    meta: {
      hasAccess: () => checkAccess(['permissions.links.view']),
    },
  },
  {
    path: '/drafts',
    name: 'drafts',
    component: () => import('../pages/Drafts/HrbrDraftsView.vue'),
    meta: {
      hasAccess: () => checkAccess(['permissions.links.view']),
    },
  },
  {
    path: '/templates',
    name: 'templates',
    component: () => import('../pages/Templates/HrbrTemplatesView.vue'),
    meta: {
      hasAccess: () => checkAccess( ['permissions.agreebuilder.view']),
    },
  },
  {
    path: '/folders',
    name: 'folders',
    component: () => import('../pages/Folders/HrbrFoldersView.vue'),
    meta: {
      hasAccess: () => checkAccess(['permissions.filesystem.folder.view']),
    },
  },
  {
    path: '/workflows',
    name: 'workflows',
    component: () => import('../pages/Workflows/HrbrWorkflowsView.vue'),
    meta: {
      hasAccess: () => checkAccess(['permissions.filesystem.folder.orgedit']),
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../pages/Settings/HrbrSettingsView.vue'),
    meta: {
      hasAccess: () => checkAccess(['permissions.settings.view']),
    },
  },
  {
    path: '/automations',
    name: 'automations',
    component: () => import('../pages/Automations/HrbrAutomationsView.vue'),
    meta: {
      hasAccess: () => checkAccess(['permissions.settings.view']),
    },
  },
  {
    path: '/advancedsearch',
    name: 'advancedsearch',
    component: () => import('../pages/AdvancedSearch/HrbrAdvancedSearchView.vue'),
    meta: {
      hasAccess: () => checkAccess(['permissions.globalsearch.view']),
    },
  },
  {
    path: '/content',
    name: 'content',
    component: () => import('../pages/Content/HrbrContentView.vue'),
    meta: {
      hasAccess: () => checkAccess(['permissions.contentframe.view']),
    },
  },
];

let intendedRoute = null;
const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

router.beforeEach((to, from, next) => {
  const { hasAccess } = to.meta;
  const harbourStore = useHarbourStore();

  intendedRoute = to;

  // Track the user's location inside the webapp 
  // for real time updates (ie: folders, dashboard, etc)
  harbourStore.setUserLocation(to.name, from.name);

  const isFunction = (val) => typeof val === 'function';
  if (hasAccess === false || (isFunction(hasAccess) && hasAccess() === false)) {
    next({ name: 'main' });
  }

  next();
});

router.afterEach((to, from) => {
  const harbourStore = useHarbourStore();

  if (harbourStore.isWorkerReady) {
    harbourStore.realtimeUserSync();
  }
});

router.onError((error) => {
  const path = intendedRoute?.path;
  const url = new URL(path, window.location.origin);
  const urlParams = new URLSearchParams(window.location.search);
  url.search = urlParams.toString();

  const scope = Sentry.getCurrentHub().getScope();
  if (scope && intendedRoute) {
    scope.setExtra('route', intendedRoute);
  }
  Sentry.captureException(error);
  if (intendedRoute && path) {
    console.error(error)
    window.location.replace(url);
  } else {
    handleDynamicError(error);
  }
});

export default router;
