<script>
import { useHarbourStore } from '@/stores/harbour-store';
import { useCkeditorStore } from '../Ckeditor/stores/ckeditor-store';
import { useTemplatesStore } from '@/pages/Templates/stores/templates-store';

export default {
  name: 'HrbrAgreementEditorRecentTemplate',

  props: {
    template: {
      type: Object,
    },
  },

  data() {
    return {
      disableStart: false,
      isOrgAdmin: false,
      isOwner: false,
    };
  },

  setup() {
    const harbourStore = useHarbourStore();
    const ckeditorStore = useCkeditorStore();
    const templatesStore = useTemplatesStore();
    return {
      harbourStore,
      ckeditorStore,
      templatesStore,
    };
  },

  computed: {
    recentTemplateDetails() {
      return this.templatesStore.getRecentTemplateDetails(this.template.agreement_uid);
    },
    templateTitle() {
      const template = this.recentTemplateDetails;
      return template?.title || '';
    },
    hasTemplatesData() {
      return this.templatesStore.myTemplates.length;
    },
    showSkeleton() {
      return !this.recentTemplateDetails && !this.hasTemplatesData;
    },
  },

  methods: {
    async createLink() {
      const template = this.recentTemplateDetails;
      if (!template) return;

      const currFolder = this.harbourStore.currentFolder;
      if (!currFolder) return;

      let customInputs = template?.custom_input_fields_json;
      const ckeditorFileId = customInputs?.ckeditoragreementid;

      let options = {
        agreementId: template.agreement_id,
        templateId: template.agreement_id,
        lastbrand: true,
      };

      if (ckeditorFileId) {
        let copyResult = null;
        try {
          this.disableStart = true;
          copyResult = await this.ckeditorStore.copyCkeditorTemplateSaveMeta(template, false, false);
        } catch (err) {
          this.$buefy.toast.open({
            message: 'Unable to create a link from this template at this time. Please try again',
            type: 'is-danger',
            position: 'is-top',
            duration: 3500,
          });
          console.error(err);
        } finally {
          this.disableStart = false;
        }

        if (!copyResult) return;

        const ckeditorOptions = {
          ckeditorFileId: copyResult.ckeditorFileId,
          ckeditorHtmlData: copyResult.ckeditorHtmlData,
          agreementId: copyResult.agreementId,
          sourceTemplateTitle: template.title,
        };
        options = { ...options, ...ckeditorOptions };
      }

      this.openAgreementLinkModalWrapper(options);
    },

    openAgreementLinkModalWrapper({
      agreementId,
      templateId = null,
      lastbrand = null,
      ckeditorFileId = null,
      ckeditorHtmlData = null,
      ckeditorAnchors = [],
      sourceTemplateTitle = null,
    }) {
      const props = {
        agreementId,
        templateId,
        lastbrand,
        ckeditorFileId,
        ckeditorHtmlData,
        ckeditorAnchors,
        sourceTemplateTitle,
      };
      this.$emit('modal-close', {});
      this.$openAgreementLinkModal({ props });
    },

    async editDocument() {
      const template = this.recentTemplateDetails;
      if (!template) return;

      this.$emit('uploading-file', true);

      let customInputJSON = template?.custom_input_fields_json;
      const props = {
        creationMode: 'template',
        agreementId: template.agreement_id,
      };

      // editing ckeditor template?
      const ckeditorFileId = customInputJSON?.ckeditoragreementid;

      if (ckeditorFileId) {
        const ckeditorHtmlData = await this.ckeditorStore.getCkeditorTemplateHtml(
          template.agreement_id,
        );
        if (ckeditorFileId && ckeditorHtmlData) {
          props.initialStepId = null;
          props.ckeditorFileId = ckeditorFileId;
          props.ckeditorHtmlData = ckeditorHtmlData;
        }
      }

      this.$emit('uploading-file', false);
      this.$emit('modal-close', {});
      this.$openAgreementEditorModal({ props });
    },

    checkIsOwner() {
      const template = this.recentTemplateDetails;
      this.email = this.harbourStore.contextDict?.systememail || '';
      this.isOrgAdmin = (this.harbourStore.contextDict?.auth_roles || []).includes('orgAdmin');
      this.isOwner = this.email == template?.original_creator_email || this.isOrgAdmin;
      return this.isOwner;
    },

    startFromTemplate() {
      const template = this.recentTemplateDetails;
      const templateGroupId = template?.template_group_id;
      if (!templateGroupId) return;
      this.templatesStore.setActiveTemplateGroup(templateGroupId);
      this.$router.push({ name: 'templates' });
      this.$emit('modal-close', {});
    },
  },
};
</script>

<template>
<div class="hrbr-recent-template">

  <div class="hrbr-recent-template__template" v-if="recentTemplateDetails">
    <div
      class="hrbr-recent-template__title"
      @click="createLink()"
      :title="templateTitle">
      <i class="fa-light fa-file"></i>
      {{ templateTitle }}
    </div>

    <div class="hrbr-recent-template__actions">
      <div
        class="hrbr-recent-template__start-button"
        :style="disableStart ? 'padding-top:2px' : 'padding-top:5px'"
        title="Create a link using this template">
        <div
          v-if="!disableStart"
          class="hrbr-recent-template__start"
          @click="createLink()">
          Start
        </div>
        <div v-else style="width: 100%; color: white">
          <i class="fal fa-spinner-third fa-spin"></i>
        </div>
      </div>
      <b-dropdown
        animation=""
        class="hrbr-recent-template__dropdown"
        :append-to-body="true"
        position="is-bottom-left">
        <template #trigger>
          <i class="fa-regular fa-ellipsis-vertical"></i>
        </template>
        <template #default>
          <b-dropdown-item
            key="edit-template-key"
            :disabled="!checkIsOwner()"
            @click="editDocument()">
            <b-icon pack="far" icon="pen-to-square"></b-icon>
            <span>Edit template</span>
          </b-dropdown-item>
          <b-dropdown-item
            key="view-in-template-key"
            @click="startFromTemplate()">
            <b-icon pack="far" icon="layer-group" type="is-danger"></b-icon>
            <span class="has-text-danger">View in templates</span>
          </b-dropdown-item>
        </template>
      </b-dropdown>
    </div>
  </div>

  <b-skeleton 
    v-else-if="showSkeleton"
    width="100%" 
    height="52px">
  </b-skeleton>
</div>
</template>

<style lang="postcss" scoped>
.hrbr-recent-template {
  display: flex;
  margin-bottom: 10px;

  &__template {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 52px;
    background: #ffffff91;
    padding: 0 10px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: #f5f6f7;
      color: #222222;

      .hrbr-recent-template__dropdown {
        visibility: visible;
      }
    }
  }
  
  &__title {
    width: 75%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    font-weight: 500;
    gap: 8px;
    display: flex;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    cursor: pointer;

    i {
      margin: auto 3px auto 1px;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    width: 20%;
  }

  &__start-button {
    padding: 5px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #ddd;
    cursor: pointer;
    background-color: #2d71ad;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    width: 75px;
    max-height: 30px;
    margin: 11px 0px 2px 2px;

    &:hover {
      background: var(--main-primary-color-activefocus);
    }
  }

  &__start {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13px;
    color: white;
    letter-spacing: 0.5px;
    font-weight: 500;
    opacity: 0.9;

    i {
      padding-right: 3px;
    }
  }

  &__dropdown {
    font-size: 13px;
    cursor: pointer;
    padding: 0 4px;
    transition: color 0.15s;
    outline: none;
    background-color: transparent;
    height: 30px !important;
    margin-top: 11px !important;
    align-items: center;
    visibility: hidden;

    &:hover {
      color: var(--main-primary-color-activefocus);
    }

    &:active {
      border-color: #2e5a89;
    }
  }
}
</style>
