// https://stackoverflow.com/questions/36170425/detect-click-outside-element
// https://gist.github.com/AnalyzePlatypus/22ca31c8f953db92eedadfe930bce31f

export default {
  bind: function (el, binding, vnode) {
    el.eventSetDrag = function () {
      el.setAttribute('data-dragging', 'yes');
    };
    el.eventClearDrag = function () {
      el.removeAttribute('data-dragging');
    };
    el.eventOnClick = function (event) {
      let dragging = el.getAttribute('data-dragging');
      // Check that the click was outside the el and its children, and wasn't a drag
      if (!(el == event.target || el.contains(event.target)) && !dragging) {
        // call method provided in attribute value
        vnode.context[binding.expression]
          ? vnode.context[binding.expression](event) // options api
          : binding.value(event); // composition api
      }
    };
    document.addEventListener('touchstart', el.eventClearDrag);
    document.addEventListener('touchmove', el.eventSetDrag);
    document.addEventListener('click', el.eventOnClick);
    document.addEventListener('touchend', el.eventOnClick);
  },
  unbind: function (el) {
    document.removeEventListener('touchstart', el.eventClearDrag);
    document.removeEventListener('touchmove', el.eventSetDrag);
    document.removeEventListener('click', el.eventOnClick);
    document.removeEventListener('touchend', el.eventOnClick);
    el.removeAttribute('data-dragging');
  },
};
