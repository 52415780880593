import Vue from 'vue';
import HrbrAgreementLinkModal from '../components/AgreementLink/HrbrAgreementLinkModal.vue';
import { ModalProgrammatic as Modal } from 'buefy';

/**
 * This plugin is needed to open a modal from any component, even from old code.
 */
function openAgreementLinkModal(options = {}) {
  const { props = {}, events = {} } = options;

  const parent = props.parent || Vue.$root;
  const modal = Modal.open({
    component: HrbrAgreementLinkModal,
    parent,
    fullScreen: true,
    hasModalCard: true,
    canCancel: ['x', 'outside'],
    customClass: 'hrbr-agreement-link-modal',
    props,
    events,
    onCancel: () => {
      if (props.isEditingApprovalFlowLink) {
        window.parent.postMessage('close', '*');
      }
    }
  });

  document.documentElement.classList.add('hrbr-agreement-link-modal--opened');

  return modal;
}

export default {
  install(Vue) {
    Vue.prototype.$openAgreementLinkModal = openAgreementLinkModal;
  },
};
